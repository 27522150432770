export const LEOS_SALE_ROUND = 2;
export const LEOS_SALES_DATA = {
  1: {
    saleRound: 1,
    seed: "Seed",
    price: 0.0001,
    saleRoundSupply: 3500000000,
    category: 8,
  },
  2: {
    saleRound: 2,
    seed: "Pre-sale",
    price: 0.0002,
    saleRoundSupply: 500000000,
    category: 9,
  },
  3: {
    saleRound: 3,
    seed: "Private",
    price: 0.0004,
    saleRoundSupply: 2750000000,
    category: 1000000, // FIXME: need to add this category to the smart contract
  },
  4: {
    saleRound: 4,
    seed: "KOL",
    price: 0.0006,
    saleRoundSupply: 333333333,
    category: 1000001, // FIXME: should add a separate category, just in case we want to change these later...
  },
  5: {
    saleRound: 5,
    seed: "Public",
    price: 0.0006,
    saleRoundSupply: 666666667,
    category: 10,
  },
};

export const LEOS_TREASURY_DATA = [
  "coinsale.tmy",
  "team.tmy",
  "legal.tmy",
  "reserves.tmy",
  "partners.tmy",
  "liquidty.tmy",
  "marketng.tmy",
  "ops.tmy",
  "infra.tmy",
  "ecosystm.tmy",
];
