import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../img/logo.png";
import settings from "../../config";
import useAuth from "../../providers/useAuth";
import Modal from "./Modal";

import { ReactComponent as Close } from "../../img/icons/close.svg";
import { ReactComponent as InfoDanger } from "../../img/icons/info-danger.svg";
import { ReactComponent as UserAvatar } from "../../img/icons/avatar.svg";
import { ReactComponent as ArrowRightCircle } from "../../img/icons/arrow-right-circle.svg";
import { AuthContext } from "../../providers/AuthProvider";

export default function Header() {
  const { user, userData } = useAuth();
  const { showPaymentIncompleteWarning } = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigation = useNavigate();
  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);
  const { pathname } = useLocation();
  const handleLogout = async (e) => {
    e.preventDefault();
    if (user) {
      await user.logout();
    }
    setShowConfirmLogoutModal(false);
    localStorage.removeItem("referralCode");
    setIsLoggedIn(false);
    navigation("/onboarding/login");
    window.location.reload();
  };
  useEffect(() => {
    if (user) {
      setIsLoggedIn(true);
    }
  }, [user]);

  const handleLogoClick = (e) => {
    if (
      pathname === "/onboarding/token-sale-agreement" ||
      pathname === "/onboarding/payment"
    ) {
      e.preventDefault();
      showPaymentIncompleteWarning(true);
    }
  };

  const handleLogoutModal = (e) => {
    e.preventDefault();
    if (
      pathname === "/onboarding/token-sale-agreement" ||
      pathname === "/onboarding/payment"
    ) {
      showPaymentIncompleteWarning(true);
    } else {
      setShowConfirmLogoutModal(true);
    }
  };

  return (
    <header id="header">
      <Modal
        isOpen={showConfirmLogoutModal}
        onClose={() => setShowConfirmLogoutModal(false)}
      >
        <Modal.Body header={false}>
          <button
            className="close_btn logout-close"
            onClick={() => {
              setShowConfirmLogoutModal(false);
            }}
          >
            <Close />
          </button>
          <div className="d-flex flex-column align-items-center justify-content-center gap-3">
            <InfoDanger />
            <h3 className="text-center mt-3">
              Are you sure you want to log out?
            </h3>
            <p className="text-center">
              In this case, you will have to start the purchase process <br />
              from the beginning
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-center mt-3 gap-3">
            <button onClick={handleLogout} className="btn btn-lg btn-dark px-5">
              Logout
            </button>
            <button
              onClick={() => setShowConfirmLogoutModal(false)}
              className="btn btn-lg btn-outline-dark modal-cancel px-5"
            >
              <span>Cancel</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="header-block h-100">
        <div className="container h-100">
          <div className="header-caption d-flex align-items-center justify-content-center h-100">
            <div className="main-logo-box flex-grow-1">
              <Link
                className="logo-bar"
                to={settings.config.landingpageUrl}
                onClick={handleLogoClick}
              >
                <img src={logo} alt="" />
              </Link>
            </div>
            {isLoggedIn ? (
              <div className="dropdown">
                <button
                  className="btn-profile fill-btn text-black dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="d-flex align-items-center gap-2">
                    <span>{userData?.baseUsername}</span>
                    <div className="btn-profile-avatar bg-white text-black align-items-center d-flex justify-content-center">
                      <UserAvatar />
                    </div>
                  </div>
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <Link
                      to="https://pangea.web4.world/"
                      target="_blank"
                      className="dropdown-item d-flex align-items-center gap-1"
                      onClick={handleLogoClick}
                    >
                      <span>Pangea</span>
                      <div className="icon-bar">
                        <ArrowRightCircle />
                      </div>
                    </Link>
                  </li>
                  {user && (
                    <li>
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={handleLogoutModal}
                      >
                        Logout
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            ) : (
              <Link
                to="https://pangea.web4.world/"
                target="_blank"
                className="fill-btn bg-white text-black d-flex align-items-center gap-1"
              >
                <span>Pangea</span>
                <div className="icon-bar">
                  <ArrowRightCircle />
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
